var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"750px","max-height":"800px","transition":"dialog-top-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-container',{staticClass:"base-card"},[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-space-between",attrs:{"cols":"12"}},[_c('p',{staticClass:"base-section-title"},[_vm._v(_vm._s(_vm.title))]),_c('v-btn',{staticClass:"dark",attrs:{"icon":"","color":"white"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-row',{class:!_vm.$vuetify.breakpoint.xsOnly ? 'px-2' : '',attrs:{"align":"center"}},[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_c('v-expansion-panels',{attrs:{"variant":"accordion"},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel',{staticClass:"base-card pa-0 mb-1",attrs:{"id":"panel1"}},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(ref){return [(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{staticClass:"text-btn text-capitalize",attrs:{"text":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("assessments.play-recording"))+" "),_c('v-icon',{staticClass:"icon ml-1",attrs:{"color":"primary"}},[_vm._v("mdi-play")])],1):_c('v-btn',{staticClass:"icon",attrs:{"icon":"","color":"primary"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-play")])],1)]}}])},[_c('div',{staticClass:"d-flex align-center"},[_c('inline-svg',{staticClass:"icon",attrs:{"src":require('@/assets/icons/bold/screenmirroring.svg')}}),_c('p',{staticClass:"base-section-sub-title mt-3 ml-3"},[_vm._v(" "+_vm._s(_vm.$t("assessments.screen-recording"))+" ")])],1)]),_c('v-expansion-panel-content',[(
                  _vm.payload && _vm.payload.urls && _vm.payload.urls.screen_recording
                )?_c('video',{ref:"videoElementScreen",attrs:{"controls":"","height":"200px","width":"100%"}},[_c('source',{attrs:{"src":_vm.payload.urls.screen_recording,"type":"video/mp4"}}),_vm._v(" "+_vm._s(_vm.$t("assessments.browser-support"))+" ")]):_c('p',{staticClass:"mb-0 base-section-sub-title danger--text"},[_vm._v(" "+_vm._s(_vm.$t("assessments.recording-not-found"))+" ")])])],1),_c('v-expansion-panel',{staticClass:"base-card pa-0 my-5",attrs:{"id":"panel2"}},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(ref){return [(!_vm.$vuetify.breakpoint.xsOnly)?_c('v-btn',{staticClass:"text-btn text-capitalize",attrs:{"text":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t("assessments.play-video"))+" "),_c('v-icon',{staticClass:"icon ml-1",attrs:{"color":"primary"}},[_vm._v("mdi-play")])],1):_c('v-btn',{staticClass:"icon",attrs:{"icon":"","color":"primary"}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-play")])],1)]}}])},[_c('div',{staticClass:"d-flex align-center"},[_c('inline-svg',{staticClass:"icon",attrs:{"src":require('@/assets/icons/bold/video.svg')}}),_c('p',{staticClass:"base-section-sub-title mt-3 ml-3"},[_vm._v(" "+_vm._s(_vm.$t("assessments.camera-recording"))+" ")])],1)]),_c('v-expansion-panel-content',[(
                  _vm.payload && _vm.payload.urls && _vm.payload.urls.camera_recording
                )?_c('video',{ref:"videoElementCamera",attrs:{"controls":"","height":"200px","width":"100%"}},[_c('source',{attrs:{"src":_vm.payload && _vm.payload.urls && _vm.payload.urls.camera_recording,"type":"video/mp4"}}),_vm._v(" "+_vm._s(_vm.$t("assessments.browser-support"))+" ")]):_c('p',{staticClass:"mb-0 base-section-sub-title danger--text"},[_vm._v(" "+_vm._s(_vm.$t("assessments.recording-not-found"))+" ")])])],1),(!_vm.is_annotataion && _vm.filtered_questions.length)?_c('v-expansion-panel',{staticClass:"base-card pa-0 mb-1",attrs:{"id":"panel3"}},[_c('v-expansion-panel-header',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{staticClass:"icon",class:{
                    'rotate-180': _vm.panel === 2
                  },attrs:{"icon":"","color":"primary"}},[_c('inline-svg',{attrs:{"src":require('@/assets/icons/linear/direction-down.svg')}})],1)]},proxy:true}],null,false,244540227)},[_c('div',{staticClass:"d-flex align-center"},[_c('inline-svg',{staticClass:"icon",attrs:{"src":require('@/assets/icons/bold/microphone.svg')}}),_c('p',{staticClass:"base-section-sub-title mt-3 ml-3"},[_vm._v(" "+_vm._s(_vm.$t("assessments.question-speaking"))+" ")])],1)]),_c('v-expansion-panel-content',{staticClass:"pl-3"},[_c('v-list',[_c('v-list-item-group',{attrs:{"color":"primary"}},_vm._l((_vm.filtered_questions),function(item,i){return _c('v-list-item',{key:i,staticClass:"\n                      d-flex\n                      align-center\n                      justify-space-between\n                      mb-3 mb-md-1\n                    "},[_c('div',[_c('p',{staticClass:"base-body-text mb-0 my-0"},[_vm._v(" "+_vm._s(((i + 1) + ". "))+" "+_vm._s(item.question.question_text)+" ")])]),_c('div',{staticClass:"d-flex align-center",class:{
                        'ml-auto': _vm.get_site_direction === _vm.SiteDirections.LTR,
                        'mr-auto': _vm.get_site_direction === _vm.SiteDirections.RTL
                      }},[_c('v-icon',{staticClass:"icon",attrs:{"color":"primary"},on:{"click":function($event){return _vm.on_click(i)}}},[_vm._v(" "+_vm._s(_vm.is_audio_playing(i) ? "mdi-pause" : "mdi-play")+" ")]),_c('v-icon',{staticClass:"icon mx-2",attrs:{"color":"primary"},on:{"click":function($event){return _vm.on_download_file(i)}}},[_vm._v("mdi-download")])],1),_c('audio',{ref:"audioElement",refInFor:true,staticStyle:{"display":"none"},attrs:{"controls":""},on:{"ended":function($event){return _vm.on_audio_end(i)}}},[_c('source',{attrs:{"src":item.answer.answer_url.location,"type":"audio/mpeg"}})])])}),1)],1)],1)],1):_vm._e()],1)],1)],1),(
        _vm.payload &&
        _vm.payload.urls &&
        _vm.payload.urls.screen_shots &&
        _vm.payload.urls.screen_shots.length
      )?_c('v-row',{staticClass:"base-card"},[_c('div',{staticClass:"test-card d-flex flex-row flex-wrap"},_vm._l((_vm.payload.urls.screen_shots),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"6"}},[_c('v-img',{attrs:{"width":"250px","src":item}},[[_c('v-icon',{staticClass:"pos",on:{"click":function($event){return _vm.view_screenshoots(index)}}},[_vm._v(" mdi-fullscreen ")])]],2)],1)}),1)]):_c('v-row',[_c('v-col',[_c('p',{staticClass:"mb-0 base-section-sub-title danger--text"},[_vm._v(" "+_vm._s(_vm.$t("assessments.screenshots-not-found"))+" ")])])],1),(_vm.is_view_screenshoot)?[_c('AssessmentDetailsScreenshoot',{attrs:{"payload":_vm.payload,"selected_index":_vm.selected_screenshoot_index},on:{"close":function($event){_vm.is_view_screenshoot = false}}})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }