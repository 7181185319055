











































import Vue from "vue";

export default Vue.extend({
  name: "AssessmentDetailsScreenshoot",
  data() {
    return {
      dialog: true,
      current_index: this.selected_index
    };
  },
  props: {
    payload: {
      type: Object
    },
    selected_index: {
      type: Number
    }
  },
  watch: {
    selected_index(newValue) {
      this.current_index = newValue;
    }
  },
  methods: {
    updateIndex(value: any) {
      this.current_index = value;
    }
  }
});
