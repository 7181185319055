





























































































































































































































































import Vue, { PropType } from "vue";
import { AppAssessments } from "@/interfaces/data_objects/app_assessments";
import AssessmentDetailsScreenshoot from "./AssessmentDetailsScreenshoot.vue";
import { mapGetters, mapMutations } from "vuex";
import { SITE_DIRECTION } from "@/store/modules/common/constants";
import { SiteDirections } from "@/store/modules/common/interfaces";
import { download_file } from "@/utils/global";
import { ROOT_ERROR } from "@/store/modules/root/constants";

export default Vue.extend({
  name: "AssessmentViewDetails",
  components: {
    AssessmentDetailsScreenshoot
  },
  data() {
    return {
      dialog: true,
      panel: [],
      selected_screenshoot_index: 0,
      filtered_questions: [] as any[],
      is_view_screenshoot: false,
      audio_array: [] as boolean[]
    };
  },
  mounted() {
    if (!this.is_annotataion) {
      if (!this.payload.result) return;
      const details = this.payload.result.details; // Getting assessment details
      // Filtering questions by filter type (short or long)
      this.filtered_questions = details?.filter(
        (item) =>
          item.question.question_options?.filter ===
          AppAssessments.QuestionFilter.SPEAKING
      );
      this.filtered_questions.forEach(() => {
        this.audio_array.push(false);
      });
    }
  },
  props: {
    title: {
      type: String
    },
    payload: {
      type: Object as PropType<AppAssessments.AssessmentResults>
    },
    is_annotataion: {
      type: Boolean
    }
  },
  watch: {
    panel() {
      this.stop_audio_and_video();
    },
    dialog(value) {
      if (!value) {
        this.stop_audio_and_video();
        this.$emit("close");
      }
    }
  },
  computed: {
    ...mapGetters("common", {
      get_site_direction: SITE_DIRECTION
    }),
    SiteDirections() {
      return SiteDirections;
    }
  },
  methods: {
    ...mapMutations({
      set_root_error: ROOT_ERROR
    }),
    on_click(index: number) {
      const wrapper: any = this.$refs.audioElement;
      if (!wrapper) return;

      // Pause all audio elements and update playing states
      wrapper.forEach((audio: HTMLAudioElement, i: number) => {
        if (i !== index) {
          audio.pause();
          this.$set(this.audio_array, i, false);
        }
      });

      // Toggle play/pause for the selected audio element
      const selected_audio = wrapper[index] as HTMLAudioElement;
      if (selected_audio.paused) {
        selected_audio.play();
        this.$set(this.audio_array, index, true);
      } else {
        selected_audio.pause();
        this.$set(this.audio_array, index, false);
      }
    },
    async on_download_file(index: number) {
      const selectedAns = this.filtered_questions[index];
      if (selectedAns?.answer?.answer_url?.location) {
        await download_file(selectedAns.answer.answer_url.location);
      } else {
        this.set_root_error(this.$t("errors.file-download-failed") as string);
      }
    },
    view_screenshoots(index: number) {
      this.selected_screenshoot_index = index;
      this.is_view_screenshoot = true;
    },
    is_audio_playing(index: number): boolean {
      return this.audio_array[index] || false;
    },
    on_audio_end(index: number) {
      this.$set(this.audio_array, index, false);
    },
    stop_audio_and_video() {
      const wrapper: any = this.$refs.audioElement;
      if (!wrapper) return;
      wrapper.forEach((audio: HTMLAudioElement) => {
        audio.pause();
      });
      this.audio_array = this.audio_array.map(() => false);
      const camera_recording = this.$refs
        .videoElementCamera as HTMLVideoElement;
      if (camera_recording) camera_recording.pause();
      const screen_recording = this.$refs
        .videoElementScreen as HTMLVideoElement;
      if (screen_recording) screen_recording.pause();
    }
  }
});
